import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <h style={{ color: 'white' }}>
        مرحبا بكم في موقع
        </h>

        <h1 style={{ color: 'purple', fontSize: 80 }}>
       شهوية.com
        </h1>

        <p>
        الافتتاح قريبا
        </p>

        <h1 style={{ color: 'pink', fontSize: 20}}>
        مريم مولودي
        </h1>

      </header>
    </div>
  );
}

export default App;
